import { miscApiConstants, MiscApiInterfaces }  from '../../../../../server/routers/apiRouters/miscRouters/base';

class PaiKeFenBanLayout {
    async download(appID: string) {
        const params: MiscApiInterfaces.GetDownloadReq = {
            appID,
        };

        const result: MiscApiInterfaces.GetDownloadRes = await httpFuns.get(miscApiConstants.apiDownload, params);
        if (result) {
            $('#a-download').attr('href', result.downloadUrl)[0].click();
        }
    }
}

const paiKeLayout             = new PaiKeFenBanLayout();
(window as any).paiKeLayout   = paiKeLayout;

$(document).ready(() => {});