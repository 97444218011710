import { frontPageConstants }                   from '../../../../../server/routers/pageRouters/frontPageRouters/paiKe/base';
import { miscApiConstants, MiscApiInterfaces }  from '../../../../../server/routers/apiRouters/miscRouters/base';

declare const paiKeMessageForm;

class PaiKeFenBanMessage {
    messageModal:   any     = null;
    verifyCodeID:   string  = '';

    pageLoaded() {
        
    }

    showMessageModal() {
        this.queryVerifyCode();
        
        if (this.messageModal) {
            this.messageModal.modal('show');
        } else {
            this.messageModal = $('#message-modal')
                .on('hidden.bs.modal', () => {
                    paiKeMessageForm.reset();
                    $('[name=paiKeMessageForm]').removeClass('was-validated');
                    const blockEle: HTMLElement = $('#message-modal .modal-body');
                    commonFuns.unblockUI(blockEle);
                }).modal('show');
        }
    }

    async queryVerifyCode() {
        const result: MiscApiInterfaces.GetVerifyCodeRes = await httpFuns.get(miscApiConstants.apiVerifyCode, {});

        if (result) {
            this.verifyCodeID = result.verifyCodeID;
            $('#verifyCodePng').show().attr('src', result.pngData);
        }
    }

    async saveMessage() {
        const checkFormOK: boolean = commonFuns.formValadition('paiKeMessageForm');
        if (!checkFormOK) {
            return;
        }

        const message       = paiKeMessageForm.message.value.trim();
        const contact       = paiKeMessageForm.contact.value.trim();
        const verifyCode    = paiKeMessageForm.verifyCode.value.trim();

        if (!message) {
            commonFuns.showAlert('留言内容不能为空', 'danger');
            return;
        }

        if (message.length > 1000) {
            commonFuns.showAlert('留言内容不能超过1000个字符', 'danger');
            return;
        }

        if (!contact) {
            commonFuns.showAlert('联系方式不能为空', 'danger');
            return;
        }

        if (!commonFuns.isEmailValid(contact) && !commonFuns.isMobileNumber(contact)) {
            commonFuns.showAlert('联系方式格式不对，应为手机号或邮箱', 'danger');
            return;
        }

        if (!verifyCode) {
            commonFuns.showAlert('验证码不能为空', 'danger');
            return;
        }

        if (verifyCode.length !== 4) {
            commonFuns.showAlert('验证码长度为4', 'danger');
            return;
        }

        const blockEle: HTMLElement = $('#message-modal .modal-body');
        commonFuns.blockUI(blockEle, '正在提交留言...');
        
        const requestParams: MiscApiInterfaces.PostMessageReq = {
            message,
            contact,
            verifyCodeID:   this.verifyCodeID,
            verifyCode:     verifyCode,
        };

        const result: MiscApiInterfaces.PostMessageReq = await httpFuns.post(miscApiConstants.apiMessage, requestParams);
        if (result) {
            location.href = `${location.protocol}//${location.hostname}${location.pathname}`;
            this.messageModal.modal('hide');
        }

        commonFuns.unblockUI(blockEle);
    }
}

const paiKeMessage        = new PaiKeFenBanMessage();
(window as any).paiKeMessage    = paiKeMessage;


$(document).ready(() => {
    if (location.pathname === frontPageConstants.pageMessage.routerPath) {
        paiKeMessage.pageLoaded();
    }
});