import { miscApiConstants, MiscApiInterfaces }  from '../../../../../server/routers/apiRouters/miscRouters/base';
import { frontPageConstants }                   from '../../../../../server/routers/pageRouters/frontPageRouters/paiKe/base';

class PaiKeShareTimetable {
    tableType   = '';

    pageLoaded() {

    }

    async verifyPassword() {
        const pwd = $('#pwd').val();
        if (!pwd) {
            commonFuns.showAlert('访问密码不能为空', 'danger');
            return;
        }

        const blockEle: HTMLElement = $('.card.password');
        commonFuns.blockUI(blockEle, '正在验证密码...');

        const hrefObj: any = commonFuns.parseLocationSearch(location.href);
        const params: MiscApiInterfaces.PostPaiKeShareCheckPwdReq = {
            paiKeTimetableID:   hrefObj.paiKeTimetableID,
            pwd:                md5(pwd),
        };

        const result: boolean = await httpFuns.post(miscApiConstants.apiPaiKeShareCheckPwd, params);
        if (result) {
            location.href = location.href;
        }

        commonFuns.unblockUI(blockEle);
    }

    selectTableType(ele: HTMLElement, tableType: string) {
        const $ele = $(ele);
        $('.search-box .input-group .input-group-btn .dropdown-menu>li>a').removeClass('active');
        $ele.addClass('active');
        $('.search-box .input-group .input-group-btn button .text').text($ele.text());
        
        this.tableType = tableType;
        this.search();
    }

    search() {
        const searchKey = $('#search-key').val();

        let listEmpty = true;
        $('.table-box').each((index: number, ele: HTMLElement) => {
            const $ele          = $(ele);
            const eleTableName  = $ele.attr('data-table-name');
            const eleTableType  = $ele.attr('data-table-type');

            if (this.tableType) {
                if (this.tableType === eleTableType && eleTableName.includes(searchKey)) {
                    $ele.removeClass('hide');
                    listEmpty = false;
                } else {
                    $ele.addClass('hide');
                }
            } else {
                if (eleTableName.includes(searchKey)) {
                    listEmpty = false;
                    $ele.removeClass('hide');
                } else {
                    $ele.addClass('hide');
                }
            }
        });

        if (listEmpty) {
            $('.no-tables').removeClass('hide');
        } else {
            $('.no-tables').addClass('hide');
        }
    }

    gotoTimetablePage(uuid: string) {
        const { origin, pathname, href } = location;
        const hrefObj: any = commonFuns.parseLocationSearch(href);
        const newHref = `${origin}${pathname}?paiKeTimetableID=${hrefObj.paiKeTimetableID}&uuid=${uuid}`;
        location.href = newHref;
    }
}

const paiKeShareTimetable              = new PaiKeShareTimetable();
(window as any).paiKeShareTimetable    = paiKeShareTimetable;


$(document).ready(() => {
    if (location.pathname === frontPageConstants.pageTimetable.routerPath) {
        paiKeShareTimetable.pageLoaded();
    }
});