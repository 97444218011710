import { miscApiConstants, MiscApiInterfaces }  from '../../../../../server/routers/apiRouters/miscRouters/base';
import { frontPageConstants }                   from '../../../../../server/routers/pageRouters/frontPageRouters/paiKe/base';

class PaiKeFenBanResult {
    tableType   = '';

    pageLoaded() {

    }

    async verifyPassword() {
        const pwd = $('#pwd').val();
        if (!pwd) {
            commonFuns.showAlert('查询密码不能为空', 'danger');
            return;
        }

        const blockEle: HTMLElement = $('.card.password');
        commonFuns.blockUI(blockEle, '正在验证密码...');

        const hrefObj: any = commonFuns.parseLocationSearch(location.href);
        const params: MiscApiInterfaces.PostFenBanShareCheckPwdReq = {
            fenbanTaskID:   hrefObj.fenbanTaskID,
            pwd:            md5(pwd),
        };

        const result: boolean = await httpFuns.post(miscApiConstants.apiFenBanShareCheckPwd, params);
        if (result) {
            location.href = location.href;
        }

        commonFuns.unblockUI(blockEle);
    }

    selectTableType(ele: HTMLElement, tableType: string) {
        const $ele = $(ele);
        $('.search-box .input-group .input-group-btn .dropdown-menu>li>a').removeClass('active');
        $ele.addClass('active');
        $('.search-box .input-group .input-group-btn button .text').text($ele.text());
        
        this.tableType = tableType;
        this.search();
    }

    async search() {
        const search    = $('#search-key').val();
        const pwdEle    = $('#pwd');
        const pwd       = pwdEle.val();

        if (!search) {
            return commonFuns.showAlert('查询条件不能为空', 'danger');
        }

        if (pwdEle.length > 0 && !pwd) {
            commonFuns.showAlert('查询密码不能为空', 'danger');
            return;
        }

        const blockEle: HTMLElement = $('.card.password');
        commonFuns.blockUI(blockEle, '正在查询...');

        const hrefObj: any = commonFuns.parseLocationSearch(location.href);
        const params: MiscApiInterfaces.PostFenBanShareSearchReq = {
            fenbanTaskID:   hrefObj.fenbanTaskID,
            search,
            pwd:            pwd ? md5(pwd) : '',
        };

        const result: MiscApiInterfaces.PostFenBanShareSearchRes = await httpFuns.post(miscApiConstants.apiFenBanShareSearch, params);
        if (result) {
            const htmlArr: string[] = [];

            for (const fenbanResult of result) {
                htmlArr.push('<hr>');
                htmlArr.push(`
                    <p>学生姓名：${fenbanResult.studentName}</p>
                    <p>证件号码：${fenbanResult.studentIDNum}</p>
                    <p>班级名称：${fenbanResult.classGradeName}</p>
                `);

                if (fenbanResult.classGradeComment) {
                    htmlArr.push(
                        `
                            <div>备注：${fenbanResult.classGradeComment}</div>
                        `
                    );
                }

                htmlArr.push('<hr>');
            }

            $('#result').html(htmlArr.join(''));
        } else {
            $('#result').html('');
        }

        commonFuns.unblockUI(blockEle);
    }

    gotoFenBanTaskPage(uuid: string) {
        const { origin, pathname, href } = location;
        const hrefObj: any = commonFuns.parseLocationSearch(href);
        const newHref = `${origin}${pathname}?fenbanTaskID=${hrefObj.fenbanTaskID}&uuid=${uuid}`;
        location.href = newHref;
    }
}

const paiKeFenBanResult              = new PaiKeFenBanResult();
(window as any).paiKeFenBanResult    = paiKeFenBanResult;


$(document).ready(() => {
    if (location.pathname === frontPageConstants.pageFenbanResult.routerPath) {
        paiKeFenBanResult.pageLoaded();
    }
});