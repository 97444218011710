const miscApiPrefix: string = '/api/misc';

const miscApiConstants = {
    apiVerifyCode:          '/verifyCode',
    apiMessage:             '/message',
    apiDownload:            '/download',
    apiRedirect:            '/redirect',
    apiQrCode:              '/qr-code',

    apiPaiKeShare:          '/paike/share',
    apiPaiKeShareCheckPwd:  '/paike/share/checkPwd',

    apiFenBanShare:         '/fenban/share',
    apiFenBanShareCheckPwd: '/fenban/share/checkPwd',
    apiFenBanShareSearch:   '/fenban/share/search',
};

const keys = Object.keys(miscApiConstants);
for (const key of keys) {
    miscApiConstants[key] = `${miscApiPrefix}${miscApiConstants[key]}`;
}

export {
    miscApiConstants,
};