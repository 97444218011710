import { CommonInterfaces } from '../../../../../interfaces';

const frontPageConstants: CommonInterfaces.FrontPageConstants = {
    pageHome: {
        routerName:     '首页',
        routerPath:     '/',
        routerIcon:     '<i class="ri-home-3-line"></i>',
        templatePath:   '/frontPages/paiKe/home/index.ejs',
    },
    pageIndex: {
        routerName:     '首页',
        routerPath:     '/index.html',
        routerIcon:     '<i class="ri-home-3-line"></i>',
        templatePath:   '/frontPages/paiKe/home/index.ejs',
    },
    pageFenBan: {
        routerName:     '分班系统',
        routerPath:     '/fenban.html',
        routerIcon:     '<i class="ri-keyboard-line"></i>',
        templatePath:   '/frontPages/paiKe/fenBan/index.ejs',
    },
    pageDownload: {
        routerName:     '下载',
        routerPath:     '/download.html',
        routerIcon:     '<i class="ri-download-line"></i>',
        templatePath:   '/frontPages/paiKe/download/index.ejs',
    },
    pageDownloadFenBan: {
        routerName:     '下载',
        routerPath:     '/fenBan/download.html',
        routerIcon:     '<i class="ri-download-line"></i>',
        templatePath:   '/frontPages/paiKe/download/index.ejs',
    },
    pageBuy: {
        routerName:     '购买',
        routerPath:     '/buy.html',
        routerIcon:     '<i class="ri-shopping-bag-3-line"></i>',
        templatePath:   '/frontPages/paiKe/buy/index.ejs',
    },
    pageBuyFenBan: {
        routerName:     '购买',
        routerPath:     '/fenBan/buy.html',
        routerIcon:     '<i class="ri-shopping-bag-3-line"></i>',
        templatePath:   '/frontPages/paiKe/buy/index.ejs',
    },
    pageNews: {
        routerName:     '资讯',
        routerPath:     `/news.html`,
        routerIcon:     '<i class="ri-file-copy-2-line"></i>',
        templatePath:   '/frontPages/paiKe/news/index.ejs',
    },
    pageNewsDetail: {
        routerName:     '资讯',
        routerPath:     `/news/:newsID.html`,
        routerIcon:     '<i class="ri-article-line"></i>',
        templatePath:   '/frontPages/paiKe/news/index.ejs',
    },
    pageMessage: {
        routerName:     '留言',
        routerPath:     `/message.html`,
        routerIcon:     '<i class="ri-message-2-line"></i>',
        templatePath:   '/frontPages/paiKe/message/index.ejs',
    },
    pageAbout: {
        routerName:     '关于',
        routerPath:     `/about.html`,
        routerIcon:     '<i class="ri-user-3-line"></i>',
        templatePath:   '/frontPages/paiKe/about/index.ejs',
    },
    pageTimetable: {
        routerName:     '课程表',
        routerPath:     `/timetable.html`,
        routerIcon:     '<i class="ri-calendar-todo-line"></i>',
        templatePath:   '/frontPages/paiKe/shareTimetable/index.ejs',
    },
    pageFenbanResult: {
        routerName:     '分班结果',
        routerPath:     `/fenbanResult.html`,
        routerIcon:     '<i class="ri-calendar-todo-line"></i>',
        templatePath:   '/frontPages/paiKe/fenbanResult/index.ejs',
    },
    page404: {
        routerName:     '404',
        routerPath:     '/404.html',
        routerIcon:     '',
        templatePath:   '/frontPages/paiKe/404/index.ejs',
    },
    pageHelp: {
        routerName:     '帮助',
        routerPath:     '/help.html',
        routerIcon:     '<i class="ri-booklet-line"></i>',
        templatePath:   '/frontPages/help/index.ejs',
    },
    pageHelpContent: {
        routerName:     '帮助',
        routerPath:     `/help/:helpCode.html`,
        routerIcon:     '<i class="ri-booklet-line"></i>',
        templatePath:   '/frontPages/help/index.ejs',
    },
    pageHelpFenBan: {
        routerName:     '帮助',
        routerPath:     '/fenBan/help.html',
        routerIcon:     '<i class="ri-booklet-line"></i>',
        templatePath:   '/frontPages/help/index.ejs',
    },
};

export {
    frontPageConstants,
};